<template>
  <div>
    <el-dialog
      title="Unfortunately, your programme doesn’t include this event."
      :visible.sync="dialogVisible"
      width="50%"
      top="3%"
      class="locked-modal"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <el-col :span="24">
        <div
          style="
            display: inline-block;
            width: 100%;
            text-align: left;
            padding: 10px 5px 10px;
          "
        >
          <div style="color: #a2b0d5">
            If you would like to upgrade your programme please
            <el-button
              type="text"
              style="
                color: #5ce6e6;
                border: 0 !important;
                background: none !important;
              "
              @click="sendEmail()"
              >click here</el-button
            >

            <br />
            and one of our mentors will contact you shortly.
          </div>
          <br />
          <div style="color: #a2b0d5">
            In the meantime, if you would like to find out more about what is
            included in our advanced <br />
            programmes click on the video to find out more.
          </div>

          <div class="video-wrapper" style="margin-top: 30px">
            <div class="player-container">
              <!-- <vue-core-video-player
                @play="handlePLay()"
                src="https://vimeo.com/1041255475"
              ></vue-core-video-player> -->

              <component
                    ref="videoModal"
                    :is="vimeoComponent"
                    video_url="https://vimeo.com/1041255475"
                    @stopLoading="stopLoading()"
                    ></component>
            </div>
          </div>
        </div>
      </el-col>
    </el-dialog>
  </div>
</template>

<script>
import VideoLockedComponent from './VideoLockedComponent.vue'
export default {
  name: "LockedEvent",
  components: {
      VideoLockedComponent
  },
  props: {
    course: {
      type: Object,
    },
  },
  data() {
    return {
      dialogVisible: false,
      vimeoComponent: null,
    };
  },
  beforeDestroy() {
    this.dialogVisible = false;
  },
  created() {
    this.dialogVisible = true;
    this.vimeoComponent = VideoLockedComponent
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
      this.$emit("close");
    },
    handlePLay() {},
    sendEmail() {
      var url =
        process.env.VUE_APP_API_UPGRADE + "/api/customer/register-interest";
      
      var content = {
        subject: "Upgrade Programme Request",
        customer_id: localStorage.getItem("customer_id"),
        meta: {
          // event_type_id: this.course.id,
          event_name: this.course.name,
          platform: "UW-PORTAL",
        },
      };
      this.axios
        .post(url, content, {
          headers: {
            // "X-Session-Key": localStorage.getItem("token"),
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            console.log("true", response.data);
            this.$notify.info({
              title: "Request Sent",
              message:
                "One of our mentors will contact you shortly. Thank you. ",
            });
          } else {
            console.log("false", response.data);
            this.$notify.error({
              title: "Oops! Unable to send a request",
              dangerouslyUseHTMLString: true,
              duration: 5000,
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
};
</script>

<style scoped>


</style>
