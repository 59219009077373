<template>
  <div>
    <vimeo-player
      v-if="video_url"
      ref="player"
      :video-url="video_url"
      :player-width="width"
      :player-height="height"
      :options="options"
      muted="muted"
      @ready="onReady"
      @play="handlePlay"
      @pause="handlePause"
      @playing="handlePlaying"
      @cuepoint="handleCuePoints"
      @ended="handleEnded"
      @timeupdate="handleTimeUpdate"
      @progress="handleProgress"
      @seeking="handleSeeking"
      @seeked="handleSeeked"
      @resize="handleResize"
      @error="handleError"
      @loaded="handleLoaded"
      @durationchange="handleDurationChange"
      @fullscreenchange="handleFullscren"
    />
  </div>
</template>

<script>
// import _ from 'lodash'
// import axios from 'axios'
/**
 * Debounce trailing time: in milliseconds
 * this is 1 second less than the interval time on
 * handleTimeUpdate method
 */
// const DEBOUNCE_TRAILING_TIME = 1000
// const API_CALL_INTERVAL = 2

// var debounceApiCall = _.debounce(function (params) {
//   const config = {
//     headers: {
//       //Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
//       'Content-Type': 'application/json',
//       'X-Requested-With': 'XMLHttpRequest',
//       Accept: 'application/json',
//       'X-Session-Key': localStorage.getItem('token'),
//     },
//   }

//   var url = process.env.VUE_APP_API_URL + '/api/lmsapp/lessons/progress'
//   var data = {
//     payload: [params],
//   }
//   axios
//     .post(url, data, config)
//     .then((response) => {
//       console.log(response, 'response')
//     })
//     .catch((error) => {
//       console.log(error)
//     })
// }, DEBOUNCE_TRAILING_TIME)

// function callLessonProgressApi(params) {
//   const config = {
//     headers: {
//       //Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
//       'Content-Type': 'application/json',
//       'X-Requested-With': 'XMLHttpRequest',
//       Accept: 'application/json',
//       'X-Session-Key': localStorage.getItem('token'),
//     },
//   }

//   var url = process.env.VUE_APP_API_URL + '/api/lmsapp/lessons/progress'
//   var data = {
//     payload: [params],
//   }
//   axios
//     .post(url, data, config)
//     .then((response) => {
//       console.log(response, 'response')
//     })
//     .catch((error) => {
//       console.log(error)
//     })
// }
export default {
  /* eslint-disable */
  name: 'VideoLockedComponent',
  props: {
    height: {
      type: Number,
      required: false,
    },
    width: {
      type: Number,
      required: false,
      default: 1280,
    },
    video_url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      options: {
        //muted: true,
        autoplay: true,
        responsive: true,
      },
      playerReady: false,
      videoUrl: '',
      is_live_event: false,
      is_complete_: false,
      lesson_duration: 0,
      video_id: null,
      timer: null,
      current_time: 0,
      pause: false,
    }
  },

  mounted() {
    // this.timer = setInterval(() => {
    //   this.eventTimer()
    // }, 10000)
  },
  beforeDestroy() {
    // clearInterval(this.timer)
  },
  created() {
    this.videoUrl = this.video_url
    // this.$store.dispatch("syncProgressState", {});
  },
  methods: {
    eventTimer() {
      // // let video_id = this.video_id
      // let time = 10
      // let item = {
      //   current_time: time,
      //   duration: time,
      //   course_id: this.course_id,
      //   video_id: this.video_id,
      //   lesson_id: this.active_lesson.id,
      //   type: 'default',
      // }
      // if (this.is_live_event === true) {
      //   item.type = 'live_event'
      //   this.$store.dispatch('updatelessonProgress', item)
      // }
    },
    handleResize(event, data) {
      // this.$emit('onResize', event)
    },
    handleDurationChange(event, data) {
      // console.log(event, data, 'duration change')
    },
    unloadVideo(value) {
    
    },
    loadVideo(value) {
     
    },
    handleReplay(value) {
     
    },
    handleTimeUpdate(event, data) {
    
    },
    percentage(num, per) {
    },
    handlePlay(event, data, player) {
      
    },
    handlePlaying(event, data) {

    },
    onReady(event) {
    },
    setCuePoints(value) {
    },
    handleCuePoints(event, data) {

    },
    setCurrentTime(event) {

    },
    handleFullscren() {},
    handleLoaded(event, data) {

    },
    handleError(event, data) {

    },
    handleTriggerPause() {
      this.$refs.player.pause()
    },
    handlePause(event, data) {
      this.current_time = event.seconds
      this.pause = true
      this.$refs.player.pause()
    },
    handleEnded(event, data) {
      
    },
    handleProgress(event, data) {
      
    },
    handleSeeking(event, data) {},
    handleSeeked() {},
  },
}
</script>

<style scoped></style>
